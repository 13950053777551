import axios from '@/config/axios'

// 点检管理-待执行列表接口
export const getMyTodoCheckTaskList = (params) => axios({
  method: 'get',
  url: `/mobile/checkTask/myTodoCheckTaskList`,
  params
})

// 点检管理-我的点检列表接口
export const getMyCheckedTaskList = (params) => axios({
  method: 'get',
  url: `/mobile/checkTask/myCheckedTaskList`,
  params
})

// 进入点检任务线路前确认接口
export const getPreCheckStatus = (taskId) => axios({
  method: 'get',
  url: `/mobile/checkTask/preCheck/${taskId}`
})

// 进入点检任务的点检线路接口
export const getEnterCheckTask = (taskId) => axios({
  method: 'get',
  url: `/mobile/checkTask/enterCheckTask/${taskId}`
})

// 退出点检任务的点检线路接口
export const getCancelCheck = (taskId) => axios({
  method: 'get',
  url: `/mobile/checkTask/cancelCheck/${taskId}`
})

// 进入点检任务点检线点检设备任务详细页接口
export const getEnterCheckLineDevice = (taskId, deviceId) => axios({
  method: 'get',
  url: `/mobile/checkTask/enterCheckLineDevice/${taskId}/${deviceId}`
})

// 提交点检任务中点检线路中单个设备的点检完成接口
export const postFinishDeviceCheck = (taskId, deviceId, checkOrder, standardId,totalTimes, params) => axios({
    method: 'post',
    url: `/mobile/checkTask/finishDeviceCheck/${taskId}/${deviceId}/${standardId}/${checkOrder}/${totalTimes}`,
    data: params
})


// 完成点检任务的点检提交接口
export const postFinishCheckTask = (taskId) => axios({
  method: 'post',
  url: `/mobile/checkTask/finishCheckTask/${taskId}`
})

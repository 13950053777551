<template>
  <div class="device-repair-wrapper">
    <div class="header-box">
      <div class="icon">
        <img :src="userIcon" alt="userIcon" />
      </div>
      <div class="text">
        <h3>{{ detailInfo&&detailInfo.maintainUserNames }}</h3>
        <span>{{ detailInfo&&detailInfo.orderStatusName }}</span>
      </div>
    </div>
    <div class="basic-box">
      <ul>
        <li>
          <span>保养设备：</span><span>{{ detailInfo&&detailInfo.deviceName+' ( '+detailInfo.deviceNo+' )' }}</span>
        </li>
        <li>
          <span>保养时间：</span><span>{{ detailInfo&&detailInfo.maintainStartTime}}</span>
        </li>
        <li>
        </li>
        <li>
        </li>
      </ul>
      <div class="detail" @click="handleGoCheckDetail">
        <span>查看详情</span>
        <i class="iconfont icontiaozhuanqianwangyoujiantouxiangyouxiayibuxianxing"></i>
      </div>
    </div>
    <div class="step-box">
      <template v-if="hasList">
        <div class="remark-box" v-for="(item,index) in childList" :key="index">
          <div class="remark-info">
            <i class="icon"></i>
            <span class="status"> {{item.status}}</span>
            <span class="time">
              {{item.time}}
            </span>
          </div>
          <div class="remark-detail">
            <span>{{item.names}} <label v-if="item.remark">{{' ：'+item.remark}}</label> </span>
            <template v-if="item.photoUrl">
              <van-image v-for="items in item.photoUrl.split(',')" :key="items" width="2.5rem" height="2.5rem" :src="imgUrl + items" />
            </template>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="remark-box"><span class="remark-box">无</span></div>
      </template>
    </div>
    <div class="tab-bar-box" v-if="detailInfo&&detailInfo.orderStatus == 3">
      <span class="left" @click="postApprovalFault(5)">拒绝</span>
      <span class="right" @click="postApprovalFault(4)">同意</span>
    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import { Step, Steps, Toast, Icon } from 'vant'
import { postApprovalFaultDetail, postApprovalFault } from '@/api/approval'
import userIcon from '@/assets/img/my/user-icon.png'
import { getPreCheckStatus } from '@/api/check'
Vue.use(Step)
Vue.use(Steps)
Vue.use(Toast)
Vue.use(Icon)
import { maintainOrderDetail, approvalOrder } from '@/api/maintain'
import { imgUrl, QiNiuUrl } from '@/config/env'

export default {
  name: 'ApprovalDetail',
  components: {
  },
  data() {
    return {
      userIcon,
      imgUrl,

      detailData: {},
      currentTaskId: 0,
      hasList: true,
      detailInfo: null,
      childList: []

    }
  },
  mounted() {
    this.maintainOrderDetail()
  },
  methods: {
    /**
     * 进入点检任务线路前确认接口
     */
    maintainOrderDetail() {
      maintainOrderDetail(this.$route.query.orderId).then((res) => {
        let details = res.details
        this.detailInfo = details
        if (details.childList && details.childList.length != '0') {
          this.childList = details.childList
          this.hasList = true
        } else {
          this.hasList = false
        }
      })
    },
    /**
     * 点检详情
     */
    handleGoCheckDetail() {
      const orderId = this.$route.query.orderId
      this.$router.push({
        path: '/maintainInfo/info',
        query: {
          id: orderId,
        },
      })
    },


    /**
     * 审批
     */
    postApprovalFault(approvalFlag) {
      const orderId = this.$route.query.orderId
      approvalOrder(orderId, approvalFlag).then((res) => {
        if (res.retCode === 1) {
          Toast.success(res.retMsg)
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        } else {
          Toast.fail(res.retMsg)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../style/mixin';
.device-repair-wrapper {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  background-color: #f1f1f1;
  /deep/.van-overlay {
    z-index: 7;
  }
  .header-box {
    @include wh(100%, 3.6rem);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 1rem;
    .icon {
      @include wh(2.4rem, 2.4rem);
      border-radius: 50%;
      margin-right: 0.5rem;
      img {
        @include wh(100%, 100%);
      }
    }
    .text {
      h3 {
        @include sc(0.75rem, #333);
      }
      span {
        @include sc(0.7rem, #ffa141);
      }
    }
  }
  .basic-box {
    background-color: white;
    box-sizing: border-box;
    padding: 0.5rem 1rem;
    ul {
      li {
        @include sc(0.7rem, #666666);
        line-height: 1.7rem;
        display: flex;
        flex-direction: row;

        span:nth-child(1) {
          font-weight: bold;
        }
        span:nth-child(2) {
          display: block;
          width: 0;
          flex: 1;
          color: #666;
        }
      }
    }
    .detail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        width: 0;
        flex: 1;
        @include sc(0.6rem, #4a8cff);
        font-weight: bold;
        margin-right: 0.5rem;
      }
      i {
        font-size: 0.8rem;
      }
    }
  }
  .step-box {
    margin-top: 0.5rem;
    background: #fff;
    .remark-box {
      padding: 0.5rem 1rem;
      .remark-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        .icon {
          width: 0.3rem;
          height: 0.3rem;
          border-radius: 50%;
          background: #409eff;
          margin-right: 0.5rem;
        }
        .status {
          color: #333333;
          font-weight: bold;
          font-size: 0.7rem;
        }
        .time {
          width: 0;
          flex: 1;
          font-size: 0.5rem;
          font-weight: bold;
          text-align: right;
        }
      }
      .remark-detail {
        padding: 0.2rem 0 0.2rem 0.8rem;
        span {
          font-size: 0.5rem;
          font-weight: bold;
          display: block;
          label {
            font-weight: 400;
            font-size: 0.5rem;
          }
        }
        .van-image {
          width: 0.5rem;
          height: 0.5rem;
          margin: 0.5rem 0.5rem 0 0;
        }
      }
    }
  }
  .tab-bar-box {
    @include wh(100%, 2.3rem);
    background-color: white;
    position: fixed;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
    border-top: 1px solid #e6e6e6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      display: block;
      height: 100%;
      font-size: 0.7rem;
      @include CC;
    }
    span.left {
      width: 50%;
      color: #666666;
    }
    span.right {
      width: 50%;
      color: white;
      background: linear-gradient(
        90deg,
        rgba(79, 147, 255, 1) 0%,
        rgba(58, 187, 253, 1) 100%
      );
    }
  }
}
</style>

import axios from '@/config/axios'

// 我的待审批列表接口
export const getMyTodoApprovalTaskList = (url, params) => axios({
  method: 'get',
  url: url,
  params
})

// 我的审批列表接口
export const getMyApprovaledTaskList = (url, params) => axios({
  method: 'get',
  url: url,
  params
})


// 审批点检任务详情页接口
export const postApprovalDetail = (taskId) => axios({
  method: 'post',
  url: `/mobile/checkTask/approvalDetail/${taskId}`
})

// 提交审批意见接口
export const postApproval = (taskId, params) => axios({
  method: 'get',
  url: `/mobile/checkTask/approval/${taskId}`,
  params
})


// 故障点检任务详情页接口
export const postApprovalFaultDetail = (orderId) => axios({
  method: 'get',
  url: `/mobile/deviceRepairOrder/detail/${orderId}`
})

// 提交故障意见接口
export const postApprovalFault = (orderId, status) => axios({
  method: 'get',
  url: `/mobile/deviceRepairOrder/approval/${orderId}/${status}`,
})


// 进入点检任务的点检线路接口
export const getApprovalEnterCheckTask = (taskId) => axios({
    method: 'get',
    url: `/mobile/checkTask/enterCheckTaskForApproval/${taskId}`
  })
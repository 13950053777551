/**
 * 配置环境变量的统一入口
 */

// api
export const baseUrl = process.env.VUE_APP_BASE_URL

// 登录api
export const loginUrl = process.env.VUE_APP_LOGIN_BASE_URL

// 图片
export const imgUrl = process.env.VUE_APP_IMG_BASE_URL

// 七牛云
export const QiNiuUrl = process.env.VUE_APP_QI_NIU_URL

// 正式地址
export const prodUrl = 'https://h5.ace.jiatai.com.cn/#/'
